import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePostsList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refTagsTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { key: 'preview', sortable: false },

    { key: 'name', label: 'Title', sortable: false },
    { key: 'subTitle', label: 'Sub title', sortable: false },
    // { key: 'type', sortable: false },
    { key: 'category', sortable: false },
    { key: 'canReserved', sortable: false },
    { key: 'isSpecial', label: 'Special offer', sortable: false },
    { key: 'isFree', label: 'Free', sortable: false },
    { key: 'isBanner', label: 'Banner', sortable: false },
    { key: 'isActive', label: 'Active', sortable: false },
    { key: 'dateRange', sortable: false },
    { key: 'timeRange', sortable: false },
    // { key: 'status', sortable: false },
    { key: 'action' },
  ]
  const perPage = ref(10)
  const totalPrograms = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const dateFilter = ref(null)
  const categoryFilter = ref(null)
  const categoryOptions = ref([])
  const typeFilter = ref(null)
  const isActiveFilter = ref(null)
  const isSpecialFilter = ref(null)
  const isFreeFilter = ref(null)
  const statusOptions = [
    {
      label: 'Active',
      value: true,
    },
    {
      label: 'In active',
      value: false,
    },
  ]
  const dataMeta = computed(() => {
    const localItemsCount = refTagsTable.value
      ? refTagsTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPrograms.value,
    }
  })

  const refetchData = () => {
    refTagsTable.value.refresh()
  }
  const fetchPrograms = (ctx, callback) => {
    console.log(dateFilter.value)
    store
      .dispatch('program/fetchPrograms', {
        q: searchQuery.value === '' ? null : searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
        date: dateFilter.value ? new Date(dateFilter.value) : null,
        isSpecial: isSpecialFilter.value,
        isFree: isFreeFilter.value,
        isActive: isActiveFilter.value,
        type: typeFilter.value,
        categoryId: categoryFilter.value,
      })
      .then(response => {
        const categories = response.body.data
        totalPrograms.value = response.body.meta.itemCount
        callback(categories)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: e.response.data.error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchProgramCategory = () => {
    store.dispatch('programCategory/fetchProgramCategories', {
      take: 50,
      page: 1,
    }).then(res => {
      if (res.body.data.length > 0) {
        categoryOptions.value = res.body.data.map(x => ({
          label: x.name,
          value: x.id,
        }))
      }
    })
  }
  fetchProgramCategory()
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      dateFilter,
      categoryFilter,
      isActiveFilter,
      isFreeFilter,
      isSpecialFilter,
      typeFilter,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusProgram = status => {
    if (status === 'draft') return 'info'
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'cancel') return 'danger'
    return 'info'
  }
  return {
    timeout,
    showTable,
    fetchPrograms,
    tableColumns,
    perPage,
    currentPage,
    totalPrograms,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTagsTable,
    refetchData,
    categoryOptions,
    dateFilter,
    categoryFilter,
    isActiveFilter,
    isSpecialFilter,
    isFreeFilter,
    statusOptions,
    resolveStatusProgram,
    typeFilter,
    // Extra
  }
}
