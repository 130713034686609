import store from '@/store'

import { computed, ref } from '@vue/composition-api'

export default function useCalendarSidebar() {
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------

  const categoryFilter = ref(null)
  const categoryOptions = ref([])

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed({
    get: () => store.state.program.selectedCalendars,
    set: val => {
      store.commit('program/SET_SELECTED_EVENTS', val)
    },
  })

  // ------------------------------------------------
  // checkAll
  // ------------------------------------------------

  return {
    selectedCalendars,
    categoryFilter,
    categoryOptions,
  }
}
