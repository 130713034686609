<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md=""
          class="mb-md-0 mb-2"
        >
          <label>Categories</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="categoryFilter"
            :options="categoryOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:categoryFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md=""
          class="mb-md-0 mb-2"
        >
          <label>Date</label>
          <b-form-datepicker
            :value="date"
            :config="{ mode: 'range'}"
            @input="(val) => $emit('update:date', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md=""
          class="mb-md-0 mb-2"
        >
          <label>Special Offer</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="isSpecialFilter"
            :options="isSpecialOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:isSpecialFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md=""
          class="mb-md-0 mb-2"
        >
          <label>Free</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="isFreeFilter"
            :options="isSpecialOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:isFreeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md=""
          class="mb-md-0 mb-2"
        >
          <label>Active</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="isActiveFilter"
            :options="isActiveOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:isActiveFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
  },
  props: {
    categoryFilter: {
      type: [Number, null],
      default: null,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    isActiveFilter: {
      type: [Boolean, null],
      default: false,
    },
    isActiveOptions: {
      type: Array,
      required: true,
    },
    isSpecialFilter: {
      type: [Boolean, null],
      default: false,
    },
    isFreeFilter: {
      type: [Boolean, null],
      default: false,
    },
    isSpecialOptions: {
      type: Array,
      required: true,
    },
    date: {
      type: [String, null],
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
